import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledDescriptionWithUsps = styled.section`
  position: relative;
  z-index: 2;
`

const StyledDescriptionWithUspsContainer = styled.div<{ view: string }>`
  ${(props) =>
    props.view === 'block' &&
    css`
      background-color: ${props.theme.color.face.light};
      border-radius: 25px;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    `};
`

const UspItem = styled.div<{ view: string }>`
  ${(props) =>
    props.view === 'default' &&
    css`
      background-color: ${props.theme.color.face.light};
      border-radius: 25px;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    `};

  & p,
  & h3 {
    color: ${({ theme }) => theme.color.text.purple};
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.l};

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size[28]};
    }

    @media (max-width: 991.98px) {
      font-size: ${({ theme }) => theme.font.size.xm};
    }
  }
`

const UspItemIcon = styled(Image)<{ view: string }>`
  position: relative;
  width: 100%;

  ${(props) =>
    props.view === 'default' &&
    css`
      @media (min-width: 992px) {
        height: 100px;
      }

      @media (max-width: 991px) {
        height: 75px;
      }

      @media (max-width: 767px) {
        height: 75px;
      }
    `};

  ${(props) =>
    props.view === 'block' &&
    css`
      @media (min-width: 992px) {
        height: 175px;
      }

      @media (max-width: 991px) {
        height: 75px;
      }

      @media (max-width: 767px) {
        height: 75px;
      }
    `};
`

interface DescriptionWithUspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_DescriptionWithUsps
}

const DescriptionWithUsps: React.FC<DescriptionWithUspsProps> = ({
  fields,
}) => (
  <StyledDescriptionWithUsps className="">
    <div className="container my-4 my-lg-5">
      <StyledDescriptionWithUspsContainer
        view={fields?.view || 'default'}
        className="py-4 py-lg-5"
      >
        <ParseContent content={fields?.description || ''} />

        <div className="row mt-lg-5 mt-4">
          {fields.usps?.map((edge) => (
            <div key={edge?.title} className="col-md-4 px-lg-4 mb-4 mb-md-0">
              <UspItem
                view={fields?.view || 'default'}
                className="px-3 py-5 h-100"
              >
                <div
                  className={`row${
                    fields?.view === 'default' ? '' : ' d-none'
                  }`}
                >
                  <div className="col-3 col-md-12" />
                  <div className="col-9 col-md-12">
                    <h3 className="text-md-center mb-4 mb-lg-5">
                      {edge?.title}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 col-md-12 mt-md-0 mt-n3 d-flex align-items-center">
                    <UspItemIcon
                      className="mb-4 mb-lg-5"
                      image={edge?.image}
                      alt={edge?.title}
                      view={fields?.view || 'default'}
                    />
                  </div>
                  <div className="col-9 col-md-12 text-md-center d-flex align-items-center">
                    <div>
                      <h3
                        className={`text-md-center mb-4${
                          fields?.view === 'default' ? ' d-none' : ''
                        }`}
                      >
                        {edge?.title}
                      </h3>
                      <ParseContent content={edge?.descriptions || ''} />
                    </div>
                  </div>
                </div>
              </UspItem>
            </div>
          ))}
        </div>
      </StyledDescriptionWithUspsContainer>
    </div>
  </StyledDescriptionWithUsps>
)

export default DescriptionWithUsps
